export default [
  {
    key: 'lotteryId',
    label: 'field.lotteryName',
    rules: 'required',
    type: '',
  },
  {
    key: 'lotteryTimeId',
    label: 'field.lotteryTime',
    rules: 'required',
    type: '',
  },
  {
    key: 'post',
    label: 'field.channel',
    rules: '',
    type: '',
  },
  {
    key: 'date',
    label: 'field.blockDate',
    rules: 'required',
    type: 'date',
    disableType: 'before',
  },
  {
    key: 'number',
    label: 'field.number',
    rules: 'required|integer|min:2|max:3',
    type: 'text',
  },
]
